<template>
  <div class="skeleton-container">
    <s-skeleton
      v-for="i in 5"
      :key="i"
      animated
      style="margin-bottom: 0.53rem;"
    >
      <template slot="template">
        <div style="display: flex;">
          <s-skeleton-item
            variant="image"
            style="width: 2.56rem; height: 3.41rem;"
          />
          <div style="flex: 1; display: flex; flex-direction: column; justify-content: space-between; margin-left: 0.32rem;">
            <div>
              <s-skeleton-item
                variant="p"
                style="width: 90%"
              />
              <s-skeleton-item
                variant="p"
                style="width: 50%"
              />
            </div>
            <div style="display: flex; justify-content: space-between; height: 0.64rem;">
              <s-skeleton-item
                variant="p"
                style="width: 50%; height: 100%;"
              />
              <s-skeleton-item
                variant="rect"
                style="width: 1.49rem; height: 100%; border-radius: 0.64rem;"
              />
            </div>
          </div>
        </div>
      </template>
    </s-skeleton>
  </div>
</template>

<script>
import { Skeleton as SSkeleton, SkeletonItem as SSkeletonItem } from '@shein/sui-mobile'

export default {
  name: 'PromotionSkeleton',
  components: {
    SSkeleton,
    SSkeletonItem,
  },
}
</script>

<style lang="less" scoped>
.skeleton-container {
  padding: 24/75rem;
  height: calc(100% - 1.52rem);
  overflow: scroll;
}
</style>
