<script setup>
import { computed } from 'vue'
import { Label as SLabel } from '@shein/sui-mobile'

const props = defineProps({
  info: {
    type: Object,
    default: () => ({})
  },
})
const view = computed(() => {
  return props.info.view
})
const styleConfig = computed(() => {
  if (!view.value) return {}
  return {
    '--bg-color': view.value.bgColor,
    '--text-color': view.value.textColor,
  }
})
</script>

<template>
  <SLabel
    v-if="view"
    :style="styleConfig"
    class="evolu-tag"
  >
    <span>{{ view.text }}</span>
  </SLabel>
</template>

<style lang="less" scoped>
.evolu-tag {
  display: inline-flex;
  align-items: center;
  background-color: var(--bg-color);
  color: var(--text-color);
}
</style>
