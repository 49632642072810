var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header",style:({
    '--sh-background-image': _vm.bgs.sh,
    '--rw-background-image': _vm.bgs.rw || _vm.bgs.sh,
    '--background-size': _vm.backgroundSize || 'cover'
  })},[_c('div',{staticClass:"title-content"},[_c('img',{class:{
        'is-opacity': _vm.promotionType !== 'clubGift',
      },attrs:{"src":"http://img.ltwebstatic.com/images3_acp/2024/09/11/c4/172604568974193a6e6ad657b23089219a17c84c81.png","alt":"","width":"10","height":"21"}}),_vm._v(" "),_c('span',{class:[
        'title',
        {
          'title_club-gift': _vm.promotionType === 'clubGift'
        }
      ]},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]),_vm._v(" "),_c('img',{class:{
        'is-opacity': _vm.promotionType !== 'clubGift',
      },attrs:{"src":"http://img.ltwebstatic.com/images3_acp/2024/09/11/07/1726045676339d20b41b2c6f764d2a1e9b6443c8cf.png","alt":"","width":"10","height":"21"}})]),_vm._v(" "),_c('div',{staticClass:"close-icon"},[_c('Icon',{attrs:{"aria-hidden":"true","name":"sui_icon_close_18px","size":"16px","color":"#fff"},on:{"click":_vm.handleClose}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }