var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.SLabel,{class:[
    'quick-ship-tag',
    _vm.info.style && `quick-ship-tag_${_vm.info.style}`
  ],style:(_setup.styleConfig),attrs:{"type":"success"}},[_c('span',{staticClass:"quick-ship-tag__title"},[(_setup.isShowIcon)?_c(_setup.Icon,{staticClass:"quick-ship-tag__icon",attrs:{"name":"sui_icon_qucikship_flat_24px","size":"12px"}}):_vm._e(),_vm._v(" "),_c('span',{class:[
        'quick-ship-tag__text',
        _vm.info.style && `quick-ship-tag__text_${_vm.info.style}`
      ]},[_vm._v("\n      "+_vm._s(_vm.info.tag_val_name_lang)+"\n    ")])],1),_vm._v(" "),(_vm.info?.tag_extra_val_name_lang)?[_c('span',{staticClass:"divider"}),_vm._v(" "),_c('span',{staticClass:"quick-ship-tag__tip"},[_c('span',{staticClass:"quick-ship-tip__text",domProps:{"innerHTML":_vm._s(_setup.handleTip(_vm.info?.tag_extra_val_name_lang))}})])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }