var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"promotion-brand__wrapper"},_vm._l((_vm.brands),function(item){return _c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
      id: '1-8-5-7',
      once: false,
      data: {
        promotionid: _vm.promotionId,
        brand_code: item.brand_code,
      }
    }),expression:"{\n      id: '1-8-5-7',\n      once: false,\n      data: {\n        promotionid: promotionId,\n        brand_code: item.brand_code,\n      }\n    }"},{name:"tap",rawName:"v-tap",value:({
      id: '1-8-5-8',
      data: {
        promotionid: _vm.promotionId,
        brand_code: item.brand_code,
        is_selected: _vm.selectedBrandCode == item.brand_code ? 1 : 0
      }
    }),expression:"{\n      id: '1-8-5-8',\n      data: {\n        promotionid: promotionId,\n        brand_code: item.brand_code,\n        is_selected: selectedBrandCode == item.brand_code ? 1 : 0\n      }\n    }"}],key:item.brand_code,class:['promotion-brand__item', {'selected': _vm.selectedBrandCode === item.brand_code}],on:{"click":function($event){return _vm.handleBrandClick(item)}}},[_vm._v("\n    "+_vm._s(item.brand_name)+"\n  ")])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }